<template>
  <div class="page-container">
    <div class="banner">
      Contact US
    </div>
    <div class="title-section">
      <div class="title">
        Our Offices
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
    </div>

    <div class="container location">
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <p>
            <b>Headquarter office</b>
          </p>
          <p>
            2F, 1305 South Suzhou Road, Shanghai, China
          </p>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <p>
            <b>General inquiries </b>
          </p>
          <p>
            <a
              target="_blank"
              href="mailto:BD@yafocapital.com"
            >BD@yafocapital.com</a>
          </p>
          <p>
            <b>ACCESS CHINA Biotech Forum</b>
          </p>
          <p>
            <a
              target="_blank"
              href="mailto:wxiang@yafocapital.com"
            >wxiang@yafocapital.com</a>
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col
          cols="12"
          sm="6"
        >
          <!--          <p>-->
          <!--            <b>US</b>-->
          <!--          </p>-->
          <!--          <p>-->
          <!--            Kun Tao, Head of US Operations-->
          <!--          </p>-->
          <!--          <p>Mobile: +1 (626)272 9282</p>-->
          <!--          <p>-->
          <!--            Email: <a-->
          <!--              target="_blank"-->
          <!--              href="mailto:ktao@yafocapital.com"-->
          <!--            >ktao@yafocapital.com</a>-->
          <!--          </p>-->
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <p>
            <b>BD</b>
          </p>
          <p>
            Qi Li, BD Director
          </p>
          <p>Mobile: +86 136-2197-9227</p>
          <p>
            Email: <a
              target="_blank"
              href="mailto:Qli@yafocapital.com"
            >Qli@yafocapital.com</a>
          </p>
        </b-col>
      </b-row>
    </div>

    <div class="map-container">
      <baidu-map
        class="map"
        :center="{lng: 121.471164, lat: 31.246705}"
        :zoom="100"
        ak="XoElhY4LH3Nyw4ciGogi5c9h4VnHuzWD"
      >
        <bm-marker :position="{lng: 121.471164, lat: 31.246705}">
          <bm-info-window
            :show="show"
          >
            <div class="marker">
              <div class="company mb-2">
                YAFO Capital
              </div>
              <div>
                Address：2F,1305 South Suzhou Road,Shanghai
              </div>
            </div>
          </bm-info-window>
        </bm-marker>
      </baidu-map>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import { BmMarker, BmInfoWindow } from 'vue-baidu-map'

export default @Component({
  components: {
    BaiduMap,
    BmMarker,
    BmInfoWindow
  }
})
class Contact extends Vue {
  show = true
}
</script>

<style scoped lang="scss">

.banner {
  background: url("../assets/images/contact/banner.jpeg");
}

.location {
  margin-top: 90px;

}

.map-container {
  margin-top: 90px;

  .map {
    width: 100%;
    height: 480px;

    ::v-deep {
      .anchorBL {
        display: none;
      }
    }
  }
}

a[target=_blank] {
  color: #e49100 !important;
}

.marker {
  .company {
    color: #e49100;
    font-size: 1rem;
  }

  font-size: 0.875rem;
}

</style>
